import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
// geolocalization: read loc field from here https://www.cloudflare.com/cdn-cgi/trace


export default function About () {


    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>About Us | Skillmeter</title>
            <meta name="description" content="Learn about Skillmeter team and mission" />
            <meta name="keywords" content="candidate testing, online testing, online assessment, skillmeter team, skillmeter mission" />

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="px-8 mx-auto text-center md:px-0">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
            About Skillmeter
          </h2>
          <p className="mt-6 text-lg font-normal text-gray-600 ">
          Learn about Skillmeter team and mission
          </p>
        </div>

        <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                    <p className="mt-8 text-lg font-normal text-gray-600">
                    Skillmeter's mission is to <span className='underline'>provide the best skills testing platform</span> to help recruiters and companies hire the right candidates for their jobs, while saving their time and money.
                    </p>
                </div>


              </div>
            </div>
          </div>
      </div>
    </section>





        <Footer />
        </>
    );
}